import React from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import UserAccount from 'pages/dashboard/UserAccount'
import MerchantProfile from '../merchants/Home'

export default function Settings() {
  return (
    <div className="user-dash__main">
      <Tabs
        defaultActiveKey="account"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="account" title="Account">
          <div className="mt-5">
            <UserAccount />
          </div>
        </Tab>
        <Tab eventKey="add-company" title="Company Profile">
          <div className="mt-5">
            <MerchantProfile />
          </div>
        </Tab>
      </Tabs>
    </div>
  )
}
