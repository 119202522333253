import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { merchantsUrl, getHeaders } from '../../config'
// eslint-disable-next-line
import { SearchOutlined } from '@ant-design/icons'
// eslint-disable-next-line
import { Button } from 'antd'

export default function MerchantApi({ merchantID, approved }) {
  const [apiKey, setApiKey] = useState(null)

  console.log(merchantID)
  useEffect(() => {
    const apiUrl = `${merchantsUrl}/${merchantID}/get-api-keys`

    axios
      .get(apiUrl, {
        headers: getHeaders()
      })
      .then(res => {
        if (res.data.length) {
          setApiKey(res.data[0])
        }
      })
      .catch(error => {
        console.log(error)
      })

    // eslint-disable-next-line
  }, [])

  function handleClick() {
    let data = {}
    const apiUrl = `${merchantsUrl}/${merchantID}/generate-api-key`
    axios
      .post(apiUrl, data, {
        headers: getHeaders()
      })
      .then(res => {
        setApiKey(res.data)
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <>
      <div className="py-4">
        <div className="d-flex align-items-center justify-content-between">
          <p>Leverage on BestQuote APIs to build awesome Insurance Products</p>
          {!apiKey ? (
            <button className="mx-4 btn btn_md btn-dark" onClick={handleClick}>
              Click to Generate API Key
            </button>
          ) : null}
        </div>
        {apiKey ? (
          <div className="card bg-light rounded border-0 p-5 m-5 shadow">
            <div className="d-flex justify-content-between align-items-center pb-5">
              <small className="text--muted">
                API KEY:{' '}
                <span className="ml-4 border-md border-grey-500">
                  {apiKey.tempKey}
                </span>
              </small>

              {/* <Button type="dashed" icon={<SearchOutlined />}>
                        Click to Copy Key
                    </Button> */}
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}
