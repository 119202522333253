import React, { useState, useEffect } from 'react'
import countryList from 'react-select-country-list'
import { Descriptions, Badge, Card, Modal } from 'antd'
import MerchantApi from './Apikeys'
import { FileImageOutlined } from '@ant-design/icons'
import CreateMerchant from './Create'
import UpdateMerchant from './Update'
import { merchantsUrl, getHeaders } from '../../config'
import axios from 'axios'

const countryOptions = countryList().getData()
const getCountry = country_ => countryOptions.find(x => x.value === country_)

function MerchantProfile(props) {
  const [merchantData, setMerchantData] = useState(null)
  const [showUpdateModal, setShowUpdateModal] = useState(false)

  const getMerchants = () => {
    axios
      .get(merchantsUrl, {
        headers: getHeaders()
      })
      .then(res => {
        setMerchantData(res.data.results[0])
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    getMerchants()

    // eslint-disable-next-line
  }, [])

  return (
    <div className="mb-5">
      {merchantData ? (
        <Badge.Ribbon
          text={merchantData.verified ? 'approved' : 'unapproved'}
          color={merchantData.verified ? 'green' : 'red'}
        >
          <Card title={merchantData.name}>
            <div className=" d-flex justify-content-between align-items-center">
              <p className="text--muted m-0">
                To change your company info click on Edit Profile
              </p>
              <button
                onClick={() => setShowUpdateModal(true)}
                className="btn btn--gradient-border ml-2rem px-3 py-2 rounded"
              >
                Edit Company Info
              </button>
            </div>
            <div className="row p-5 border-bottom">
              <div className="col-md-2">
                {merchantData.logo ? (
                  <img src={merchantData.logo} alt="company logo" />
                ) : (
                  <FileImageOutlined />
                )}
                <h4>{merchantData.name}</h4>
              </div>
              <div className="col-md-10">
                <Descriptions layout="vertical">
                  <Descriptions.Item label="Website">
                    {merchantData.website}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {merchantData.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Phone Number">
                    {merchantData.phoneNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label="Address">
                    {merchantData.address}, {merchantData.city},{' '}
                    {getCountry(merchantData.country).label}{' '}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            {merchantData.verified ? (
              <div>
                <MerchantApi merchantID={merchantData.id} />
              </div>
            ) : null}
          </Card>
        </Badge.Ribbon>
      ) : (
        <CreateMerchant handleSuccess={getMerchants} />
      )}

      <Modal
        title="Update Company Info"
        centered
        visible={showUpdateModal}
        onOk={() => setShowUpdateModal(true)}
        onCancel={() => setShowUpdateModal(false)}
        footer={null}
      >
        <UpdateMerchant
          merchantData={merchantData}
          handleSuccess={() => {
            getMerchants()
            setShowUpdateModal(false)
          }}
        />
      </Modal>
    </div>
  )
}

export default MerchantProfile
