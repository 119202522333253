import React, { useState } from 'react'
import { Form } from 'antd'
import countryList from 'react-select-country-list'
import axios from 'axios'
import { merchantsUrl, getHeaders, successNotification } from '../../config'
import BasicInput from '../../components/Forms/BasicInput'
import SelectInput from '../../components/Forms/SelectInput'
import { LoadingOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const countryOptions = countryList().getData()

function UpdateMerchant(props) {
  const { merchantData, handleSuccess } = props
  const [company, setCompany] = useState({
    name: merchantData.name,
    website: merchantData.website,
    email: merchantData.email,
    phoneNumber: merchantData.phoneNumber,
    country: merchantData.country,
    city: merchantData.city,
    address: merchantData.address
    // logo: null,
    // documents: [],
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})

  const removeErrorMessage = stateName => {
    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        nonFieldErrors: null
      })
    } else if (errorMessage.vehicles) {
      setErrorMessage({
        ...errorMessage,
        vehicles: errorMessage.vehicles.map(v => {
          return { ...v, [stateName]: null }
        }),
        nonFieldErrors: null
      })
    }
  }

  const handleSubmit = async () => {
    let data = {
      ...company
      // startDate: policy.startDate
      // ? moment(policy.startDate).format('YYYY-MM-DD')
      // : '',
      // dateOfBirth: company.dateOfBirth
      // ? moment(policy.dateOfBirth).format('YYYY-MM-DD')
      // : ''
    }

    setIsSubmitting(true)
    axios
      .put(`${merchantsUrl}/${merchantData.id}`, data, {
        headers: getHeaders()
      })
      .then(res => {
        setIsSubmitting(false)
        handleSuccess()
        successNotification('Successfully updated organization')
      })
      .catch(err => {
        setIsSubmitting(false)
        if (err.response) {
          err.response.status === 400
            ? setErrorMessage({
                ...err.response.data,
                nonFieldErrors: [
                  err.response.data?.message
                  // "Kindly make sure you've completed the form"
                ]
              })
            : setErrorMessage({
                ...errorMessage,
                nonFieldErrors: ['Oops, server Error! Retry']
              })
        } else {
          setErrorMessage({
            ...errorMessage,
            nonFieldErrors: ['Error Connecting to Server, Retry.']
          })
        }
      })
  }

  const handleFieldUpdate = stateName => event => {
    setCompany({ ...company, [stateName]: event.target.value })

    // remove form field errors
    removeErrorMessage(stateName)
  }

  const handleSelectUpdate = stateName => value => {
    setCompany({ ...company, [stateName]: value })
    console.log(value)
    // remove form field errors
    removeErrorMessage(stateName)
  }

  return (
    <Form
      onFinish={handleSubmit}
      layout="vertical"
      className="px-4 py-4"
      style={{
        border: '1px solid #f2f2f2',
        boxShadow: 'none'
      }}
    >
      <div className="row px-4 py-4">
        <h3 className="mb-5 px-4 py-2 ">Update your company's information</h3>

        <div className="col-md-12 mb-2">
          <BasicInput
            label="Kindly enter company's name"
            value={company.name}
            name={'name'}
            placeholder="eg. Prime Enterprise"
            handleChange={handleFieldUpdate('name')}
            required={true}
          />

          {errorMessage.name
            ? errorMessage.name.map((err, i) => (
                <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                  {err}
                </p>
              ))
            : null}
        </div>

        <div className="col-md-12 mb-2">
          <BasicInput
            label="Kindly enter company website"
            value={company.website}
            name={'website'}
            placeholder="www.abc.com"
            handleChange={handleFieldUpdate('website')}
            required={true}
          />

          {errorMessage.website
            ? errorMessage.website.map((err, i) => (
                <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                  {err}
                </p>
              ))
            : null}
        </div>

        <div className="col-md-12 mb-2">
          <BasicInput
            label="Kindly provide company phone number"
            value={company.phoneNumber}
            name={'phone number'}
            placeholder="enter phone number"
            handleChange={handleFieldUpdate('phoneNumber')}
            required={true}
          />

          {errorMessage.phoneNumber
            ? errorMessage.phoneNumber.map((err, i) => (
                <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                  {err}
                </p>
              ))
            : null}
        </div>

        <div className="col-md-12 mb-2">
          <BasicInput
            label="Kindly provide company email address"
            inputType="email"
            value={company.email}
            name={'email address'}
            placeholder="enter email address"
            handleChange={handleFieldUpdate('email')}
            required={true}
          />

          {errorMessage.email
            ? errorMessage.email.map((err, i) => (
                <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                  {err}
                </p>
              ))
            : null}
        </div>
        <div className="col-md-12 mb-2">
          <BasicInput
            label="Kindly provide company address"
            inputType="address"
            value={company.address}
            name={'address'}
            placeholder="enter company address"
            handleChange={handleFieldUpdate('address')}
            required={true}
          />

          {errorMessage.email
            ? errorMessage.email.map((err, i) => (
                <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                  {err}
                </p>
              ))
            : null}
        </div>
        <div className="col-md-6 mb-2">
          <BasicInput
            label="City"
            inputType="city"
            value={company.city}
            name={'city'}
            placeholder="City"
            handleChange={handleFieldUpdate('city')}
            required={true}
          />

          {errorMessage.email
            ? errorMessage.email.map((err, i) => (
                <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                  {err}
                </p>
              ))
            : null}
        </div>
        <div className="col-md-6 mb-2">
          <SelectInput
            label={"Company's Country of origin"}
            value={company.country}
            name={'Company Country'}
            optionList={countryOptions}
            // optionList={[
            //   { label: 'Use an existing client', value: false },
            //   { label: 'Add a new client', value: true }
            // ]}
            onChange={handleSelectUpdate('country')}
            required={true}
          />

          {errorMessage.country
            ? errorMessage.country.map((err, i) => (
                <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                  {err}
                </p>
              ))
            : null}
        </div>

        <div className="col-md-12 mb-2rem">
          {errorMessage.nonFieldErrors
            ? errorMessage.nonFieldErrors.map((err, i) => (
                <div style={{ color: 'red', marginTop: '1em' }} key={i}>
                  {err}
                </div>
              ))
            : null}
        </div>
        <div className="motor_form_buttons">
          <button className="btn_normal btn_gradient btn_md" type="submit">
            {isSubmitting ? <LoadingOutlined /> : 'Submit'}
          </button>
        </div>
      </div>
    </Form>
  )
}

UpdateMerchant.propTypes = {
  handleSuccess: PropTypes.func,
  merchantData: PropTypes.object
}

UpdateMerchant.defaultProps = {
  handleSuccess: () => {}
}

export default UpdateMerchant
